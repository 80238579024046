<template>
  <a-spin tip="加载中" :spinning="loading">
    <div class="tableList">
      <div class="search" v-if="info == null">
        <div class="search-body" style="height: 50px">
          <!--          <a-button style="float: right" @click="gotoList" type="default"-->
          <!--            >专项预算列表</a-button-->
          <!--          >-->
        </div>
      </div>

      <div
        :style="{
          padding: '10px',
          width: info == null ? '90%' : '100%',
          margin: '0px auto',
        }"
      >
        <a-form-model
          ref="specialFundform"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <table style="width: 100%">
            <tr>
              <td
                colspan="3"
                style="
                  font-size: 40px;
                  text-align: center;
                  padding-bottom: 20px;
                "
              >
                河北省教育科学研究院专项经费支出预算表
              </td>
            </tr>
            <tr>
              <td>
                <a-form-model-item
                  label="编报部门"
                  :wrapperCol="{ span: 16 }"
                  prop="orgId"
                  style="margin-bottom: 0px"
                >
                  <a-cascader
                    :options="orgs"
                    v-model="form.orgId"
                    @change="orgChanged"
                    :fieldNames="{
                      label: 'name',
                      value: 'id',
                      children: 'children',
                    }"
                    placeholder="请选择部室"
                  />
                </a-form-model-item>
              </td>

              <td>
                <a-form-model-item
                  label="编报人"
                  :wrapperCol="{ span: 16 }"
                  prop="postUser"
                  style="margin-bottom: 0px"
                >
                  <a-input readOnly v-model="form.postUserName" />
                </a-form-model-item>
              </td>
              <td>
                <a-form-model-item
                  label="编报时间"
                  :labelCol="{ span: 10 }"
                  :wrapperCol="{ span: 14 }"
                  prop="reportTime"
                  style="margin-bottom: 0px"
                >
                  <a-date-picker v-model="form.reportTime" />
                </a-form-model-item>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="padding-top: 10px">
                <table class="formtable">
                  <tr>
                    <td style="width: 200px">
                      <span style="color: red">*</span>项目名称
                    </td>
                    <td style="text-align: left">
                      <a-form-model-item
                        prop="xiangmu"
                        style="margin-bottom: 0px"
                      >
                        <a-input v-model="form.xiangmu" />
                      </a-form-model-item>
                    </td>
                    <td style="width: 150px">
                      <span style="color: red">*</span>项目负责人
                    </td>
                    <td style="text-align: left">
                      <a-form-model-item
                        prop="fuzeren"
                        style="margin-bottom: 0px"
                      >
                        <a-input-search
                          class="select_search"
                          placeholder="请选择"
                          readOnly
                          v-model="form.fuzeren"
                          @click="onSearch"
                          @search="onSearch"
                        >
                          <a-button
                            type="primary"
                            slot="enterButton"
                            icon="plus"
                            title="添加"
                          />
                        </a-input-search>
                      </a-form-model-item>
                    </td>
                    <td style="width: 200px">
                      <span style="color: red">*</span>类型
                    </td>
                    <td style="text-align: left">
                      <a-form-model-item prop="type" style="margin-bottom: 0px">
                        <a-select v-model="form.type">
                          <a-select-option value="科研">科研</a-select-option>
                          <a-select-option value="教研">教研</a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </td>
                  </tr>
                  <tr>
                    <td><span style="color: red">*</span>预算总额（万元）</td>
                    <td style="text-align: left">
                      <a-form-model-item
                        prop="amount"
                        style="margin-bottom: 0px"
                      >
                        <a-input-number
                          :min="0"
                          v-model="form.amount"
                          style="width: 100%; text-align: right"
                        />
                      </a-form-model-item>
                    </td>
                    <td><span style="color: red">*</span>支出事项</td>
                    <td style="text-align: left">
                      <a-form-model-item
                        prop="shixiang"
                        style="margin-bottom: 0px"
                      >
                        <a-input v-model="form.shixiang" />
                      </a-form-model-item>
                    </td>
                    <td>
                      <span style="color: red">*</span
                      >申请从专项经费中列支数（元）
                    </td>
                    <td style="text-align: left">
                      <a-form-model-item
                        prop="liezhishu"
                        style="margin-bottom: 0px"
                      >
                        <a-input v-model="form.liezhishu" readOnly />
                      </a-form-model-item>
                    </td>
                  </tr>

                  <tr>
                    <td><span style="color: red">*</span>项目预算明细</td>
                    <td colspan="5" style="padding: 0px">
                      <a-row>
                        <a-col
                          :span="7"
                          style="
                            border-bottom: solid 1px #cccccc;
                            height: 50px;
                            line-height: 50px;
                          "
                          >列支科目</a-col
                        >
                        <a-col
                          :span="4"
                          style="
                            border-left: solid 1px #cccccc;
                            border-bottom: solid 1px #cccccc;
                            height: 50px;
                            line-height: 50px;
                          "
                          >金额（元）</a-col
                        >
                        <a-col
                          :span="13"
                          style="
                            border-left: solid 1px #cccccc;
                            border-bottom: solid 1px #cccccc;
                            height: 50px;
                            line-height: 50px;
                          "
                          >使用资金的理由及与项目的关系</a-col
                        >
                      </a-row>
                      <a-row v-for="(item, index) in form.items" :key="index">
                        <a-col
                          :span="7"
                          :style="{
                            borderBottom: 'solid 1px #cccccc',
                            height: '50px',
                            padding: '5px',
                          }"
                        >
                          <a-select v-model="item.xiangmu" allowClear>
                            <a-select-opt-group
                              v-for="group in xiangmuDataSource"
                              :key="group.id"
                            >
                              <span
                                slot="label"
                                style="color: #0000cc; font-size: 16px"
                              >
                                {{ group.itemValue }}
                              </span>
                              <a-select-option
                                v-for="opt in group.children"
                                :key="opt.id"
                                :value="opt.itemValue"
                                >{{ opt.itemValue }}</a-select-option
                              >
                            </a-select-opt-group>
                          </a-select>
                        </a-col>
                        <a-col
                          :span="4"
                          :style="{
                            borderLeft: 'solid 1px #cccccc',
                            borderBottom: 'solid 1px #cccccc',
                            height: '50px',
                            padding: '5px',
                          }"
                        >
                          <a-input-number
                            v-model="item.amount"
                            @change="itemAmountChange"
                          />
                        </a-col>
                        <a-col
                          :span="13"
                          :style="{
                            borderLeft: 'solid 1px #cccccc',
                            borderBottom: 'solid 1px #cccccc',
                            height: '50px',
                            padding: '5px',
                          }"
                        >
                          <a-input v-model="item.remark" />
                        </a-col>
                      </a-row>
                    </td>
                  </tr>
                  <tr>
                    <td>附件</td>
                    <td colspan="5" style="text-align: left">
                      <a-upload
                        :action="uploadFileUrl"
                        :fileList="fileList"
                        @change="handleFileChange"
                        @preview="showWpsRead"
                        :remove="() => upLoadRemove"
                      >
                        <div
                          v-if="this.form.status == 0 || this.form.status == 3"
                        >
                          <a-button>
                            <a-icon type="upload" />上传文件
                          </a-button>
                        </div>
                      </a-upload>
                    </td>
                  </tr>
                  <tr>
                    <td>经办人</td>
                    <td style="text-align: left; width: 250px">
                      <a-form-model-item
                        prop="postUser"
                        style="margin-bottom: 0px"
                        v-if="form.status == 0 || form.status == 3"
                      >
                        <a-input
                          readOnly
                          v-model="form.postUserName"
                          style="width: 100%"
                        />
                      </a-form-model-item>
                      <div style="text-align: right" v-else>
                        <img
                          style="width: 120px"
                          :src="form.postUserPic"
                          v-if="form.postUserPic"
                        />
                        <span v-else>{{ form.postUserName }} </span>
                        <div>
                          {{ form.reportTime.format("YYYY年MM月DD日") }}
                        </div>
                      </div>
                    </td>
                    <td>项目负责人意见</td>
                    <td style="width: 250px">
                      <auditPrint
                        :infoData="form"
                        taskPrintKey="XiangMuFuZheRen"
                        @callback="auditCb"
                      />
                    </td>
                    <td>项目管理部门负责人意见</td>
                    <td style="width: 250px">
                      <auditPrint
                        :infoData="form"
                        taskPrintKey="BuShiFuZeRen"
                        @callback="auditCb"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>财务意见</td>
                    <td>
                      <auditPrint
                        :infoData="form"
                        taskPrintKey="CaiWu"
                        @callback="auditCb"
                      />
                    </td>
                    <td>办公室财务副主任意见</td>
                    <td>
                      <auditPrint
                        :infoData="form"
                        taskPrintKey="BanGongShiFuZhuRen"
                        @callback="auditCb"
                      />
                    </td>
                    <td>办公室主任意见</td>
                    <td>
                      <auditPrint
                        :infoData="form"
                        taskPrintKey="BanGongShiZhuRen"
                        @callback="auditCb"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>项目管理部门分管领导意见</td>
                    <td>
                      <auditPrint
                        :infoData="form"
                        taskPrintKey="FenGuanLingDao"
                        @callback="auditCb"
                      />
                    </td>
                    <td>分管财务院领导意见</td>
                    <td>
                      <auditPrint
                        :infoData="form"
                        taskPrintKey="BanGongShiFenGuanLingDao"
                        @callback="auditCb"
                      />
                    </td>
                    <td>院长意见</td>
                    <td>
                      <auditPrint
                        :infoData="form"
                        taskPrintKey="YuanChang"
                        @callback="auditCb"
                      />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <a-row style="text-align: center; margin-top: 20px">
            <a-col>
              <a-button
                type="primary"
                v-if="form.status == 0 || form.status == 3"
                style="margin-right: 10px"
                @click="handleSubmit(0)"
                >保存</a-button
              >
              <a-popconfirm
                v-if="form.status == 0 || form.status == 3"
                title="提交后数据不可更改，是否确认？"
                @confirm="handleSubmit(1)"
              >
                <a-button type="danger" style="margin-right: 10px"
                  >提交</a-button
                >
              </a-popconfirm>
              <a-button
                type="primary"
                v-if="form.processExecuteStatus"
                style="margin-right: 10px"
                @click="showaudit()"
                >审批</a-button
              >
              <a-button
                type="default"
                v-if="form.status == 2"
                @click="showWps"
                class="cancelBtn"
                >打印</a-button
              >
              <a-button @click="cancelClick">取消</a-button>
            </a-col>
          </a-row>
        </a-form-model>
        <a-modal
          title="选择用户"
          v-model="selectuseModal.visible"
          width="70%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <selectuser @callback="selectuserCallback" />
        </a-modal>
        <a-modal
          title="审批"
          v-model="auditModal.visible"
          width="40%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <audit
            :info="auditModal.info"
            :formValidPass="formValidPass"
            @callback="auditCallBack"
          />
        </a-modal>
      </div>
    </div>
  </a-spin>
</template>
<script>
import specialfundApi from "@/api/work/specialfund";
import orgApi from "@/api/org";
import selectuser from "../components/searchuser.vue";
import pinyin from "js-pinyin";
import dictionaryItemApi from "@/api/system/dictionaryItem";
import request from "@/request";
const columns = [
  {
    align: "center",
    dataIndex: "xiangmu",
    title: "列支科目",
    scopedSlots: { customRender: "testcomplete" },
  },
  {
    align: "center",
    dataIndex: "amount",
    title: "金额",
    scopedSlots: { customRender: "number" },
  },
  {
    align: "center",
    dataIndex: "remark",
    title: "使用资金的理由及与项目的关系",
    scopedSlots: { customRender: "textbox" },
  },
];

export default {
  name: "specialfund",
  data() {
    return {
      form: {
        orgId: [],
        reportTime: null,
        fuzeren: "",
        fuzerenId: "",
        amount: 0,
        liezhishu: 0,
        status: 0,
        items: [{}, {}, {}, {}, {}, {}, {}],
      },
      xiangmuDataSource: [],
      xiangmuDataSourceBack: [],
      uploadFileUrl: "",
      fileList: [],
      upLoadRemove: false,
      columns,
      labelCol: { span: 6 },
      wrapperCol: { span: 23 },
      orgs: [],
      orgDates: [],
      loading: false,
      selectuseModal: {
        visible: false,
        record: null,
        type: 0,
      },
      auditModal: {
        info: null,
        visible: false,
      },
      rules: {
        orgId: [
          {
            required: true,
            message: "请选择部室",
            trigger: "change",
          },
        ],
        reportTime: [
          {
            required: true,
            message: "请选择编报时间",
            trigger: "change",
          },
        ],
        xiangmu: [
          {
            required: true,
            message: "请输入项目名称",
            trigger: "blur",
          },
        ],
        fuzeren: [
          {
            required: true,
            message: "请选择项目负责人",
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: "请选择类型",
            trigger: "change",
          },
        ],
        amount: [
          {
            required: true,
            message: "请输入预算总额",
            trigger: "blur",
          },
        ],
        shixiang: [
          {
            required: true,
            message: "请输入支出事项",
            trigger: "blur",
          },
        ],
        liezhishu: [
          {
            required: true,
            message: "请输入列支数",
            trigger: "blur",
          },
        ],
      },
      formValidPass: true,
    };
  },
  props: {
    info: {
      type: Object,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  components: { selectuser },
  mounted() {
    this.getOrgTree();
    this.getXiangmuDataSource();
    this.uploadFileUrl = request.getUploadUrl("document");
    if (this.info && this.info.id > 0) {
      this.getData();
    } else {
      this.form.reportTime = this.$moment();
      this.upLoadRemove = true;
    }
  },
  methods: {
    itemAmountChange(v) {
      let amount = 0;
      this.form.items.forEach((a) => {
        if (!isNaN(parseFloat(a.amount)))
          amount = amount + parseFloat(a.amount);
      });
      this.form.liezhishu = amount;
    },
    auditCb(data) {
      this.$emit("callback", data != null);
      this.getData();
    },
    handleFileChange(fileInfo) {
      this.fileList = fileInfo.fileList;
      if (fileInfo.file.status == "error") {
        this.$message.error("上传失败");
      }
    },
    getXiangmuDataSource() {
      dictionaryItemApi.getItemByDictionaryName("列支科目").then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          let data = res.data;
          let groups = data.filter((a) => a.parentId == 0);
          groups.forEach((e, i) => {
            e.children = data.filter((a) => a.parentId == e.id);
          });
          this.xiangmuDataSource = groups;
          this.xiangmuDataSourceBack = groups;
        }
      });
    },
    dataGroup(v, s) {
      let data = [];
      if (v != "") {
        this.xiangmuDataSourceBack.forEach((a) => {
          let _data = Object.assign([], a);
          _data.children = a.children.filter(
            (b) => b.itemValue.indexOf(v) > -1
          );
          if (_data.children.length > 0) data.push(_data);
        });
      } else data = this.xiangmuDataSourceBack;
      this.xiangmuDataSource = data;
    },
    gotoList() {
      this.$router.push({ path: "/business/specialfundlist" });
    },
    async showaudit() {
      let validRes = await this.$refs.specialFundform.validate();
      if (!validRes) {
        this.$message.error("请填写必填项");
        return;
      }
      this.formValidPass = validRes;
      this.auditModal.info = this.form;
      this.auditModal.visible = true;
    },
    async auditCallBack(data) {
      let close = true;
      this.auditModal.visible = false;
      if (data != null && data.updateModel) {
        close = false;
        let validRes = await this.$refs.specialFundform.validate();
        if (!validRes) {
          return false;
        }
        this.loading = true;
        this.loadingTip = "保存中...";
        let formData = {
          id: this.info.id,
          xiangmu: this.form.xiangmu,
          fuzeren: this.form.fuzeren,
          fuzerenId: this.form.fuzerenId,
          type: this.form.type,
          amount: this.form.amount,
          shixiang: this.form.shixiang,
          liezhishu: this.form.liezhishu,
          items: this.form.items,
          updateOnly: true,
        };
        let res = await specialfundApi.addOrUpdate(formData);
        if (res.errorCode == "0000") {
          this.loading = false;
          close = true;
        }
      }
      if (close) {
        this.$emit("callback", data != null);
      }
    },
    orgChanged(v, selectedOptions) {
      if (selectedOptions == null || selectedOptions.length == 0) {
        this.form.orgName = null;
        this.form.orgId = [];
      } else {
        this.form.orgName = selectedOptions[selectedOptions.length - 1].name;
        this.form.orgId = v;
      }
      this.$forceUpdate();
    },
    onSearch(record, type) {
      this.selectuseModal.visible = true;
      this.selectuseModal.record = record;
      this.selectuseModal.type = type;
    },
    selectuserCallback(user) {
      this.form.fuzerenId = user.userName;
      this.form.fuzeren = user.realName;
      this.selectuseModal.visible = false;
    },
    getOrgTree() {
      orgApi.getTree(true, true).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.orgDates = res.data.tree[0].children;
          res.data.tree[0].orgsNumber = this.orgsNumber;
          this.orgsNumber = this.orgsNumber++;
          this.orgs = res.data.tree;
          if (!this.data) {
            // this.form.type=res.data.path;
            this.form.orgId = res.data.path;
            let tree = this.orgs;
            for (var i = 0; i < res.data.path.length; ++i) {
              var id = res.data.path[i];
              var find = tree.filter((n) => n.id == id);
              if (i == res.data.path.length - 1) {
                // this.form.type=find[0].name;
                this.form.orgName = find[0].name;
              } else {
                tree = find[0].children;
              }
            }
          }
          this.form.postUserName = res.data.realName;
          this.form.postUser = localStorage.getItem(
            this.$commons.User.userName
          );
        }
      });
    },
    getData() {
      this.loading = true;
      specialfundApi.getData(this.info.id).then((res) => {
        this.loading = false;
        if (res.errorCode == this.$commons.RespCode.success) {
          res.data.orgId = res.data.orgPath;
          delete res.data.orgPath;
          res.data.reportTime = this.$moment(res.data.reportTime, "YYYY-MM-DD");
          this.form = { ...res.data };
          if (
            this.info &&
            this.info.taskId &&
            this.info.taskId != this.form.taskId
          ) {
            this.form.taskId = this.info.taskId;
          }
          if (res.data.files) {
            var d = JSON.parse(res.data.files);
            var files = [];
            for (var i = 1; i <= d.length; ++i) {
              var a = d[i - 1];
              files.push({
                uid: i,
                name: a.title,
                status: "done",
                response: a,
                url: a.url,
              });
            }
            if (res.data.status == 0 || res.data.status == 3)
              this.upLoadRemove = true;
            this.fileList = files;
          }
        }
      });
    },
    handleSubmit(status) {
      this.$refs.specialFundform.validate((valid) => {
        if (valid) {
          let itemsCheck = this.form.items.filter(
            (a) => a.xiangmu && a.xiangmu != ""
          );
          if (itemsCheck.length == 0) {
            this.$message.error("请填写项目预算明细");
            return;
          }
          let formData = { ...this.form, status };
          formData.orgId = formData.orgId[formData.orgId.length - 1];
          this.loading = true;
          if (this.info) {
            formData.id = this.info.id;
          }
          delete formData.createTime;
          delete formData.creator;
          delete formData.creatorName;
          delete formData.auditRecords;
          formData.reportTime = formData.reportTime.format("YYYY-MM-DD");
          if (this.fileList.length > 0) {
            var file = [];
            this.fileList.forEach((f) => {
              file.push({
                title: f.response.title,
                url: f.response.url,
              });
            });
            formData.files = JSON.stringify(file);
          }
          let extensionSetting = {};
          if (formData.fuzerenId) {
            extensionSetting["node_专项资金预算-项目负责人意见"] = {
              targetUsers: formData.fuzerenId,
              count: formData.fuzerenId.split(",").length,
            };
          }
          formData.extensionSetting = JSON.stringify(extensionSetting);
          //添加
          specialfundApi
            .addOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == this.$commons.RespCode.success) {
                this.$message.success(res.errorMsg);
                if (this.modal) {
                  this.$emit("callback", true);
                } else {
                  this.gotoList();
                }
              } else {
                this.$message.error(res.errorMsg);
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    cancelClick() {
      this.$emit("callback", false);
    },
    showWpsRead(file) {
      let type = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        type.toLowerCase().indexOf("doc") <= -1 &&
        type.toLowerCase().indexOf("xls") <= -1 &&
        type.toLowerCase().indexOf("ppt") <= -1 &&
        type.toLowerCase().indexOf("pdf") <= -1
      ) {
        window.open(file.response.url, "_blank");
      } else {
        let fileId =
          "file" +
          file.response.url
            .substring(
              file.response.url.lastIndexOf("/") + 1,
              file.response.url.lastIndexOf(".")
            )
            .replaceAll("-", "");
        let url = file.response.url.substring(
          file.response.url.indexOf("oabucket")
        );
        let type = file.response.url.substring(
          file.response.url.lastIndexOf(".") + 1
        );
        localStorage.removeItem("fileRrl");
        localStorage.setItem("fileRrl", url);
        localStorage.removeItem("fileName");
        localStorage.setItem("fileName", file.name);
        localStorage.removeItem("fileId");
        localStorage.setItem("fileId", fileId);
        localStorage.removeItem("fileTpye");
        localStorage.setItem("fileTpye", type);
        const jump = this.$router.resolve({
          name: "wpsRead",
          query: {
            //要传的参数
            fileName: file.name,
          },
        });

        window.open(jump.href, "_blank");
      }
    },
    showWps() {
      let templateId = "specialfund";

      let innerData = Object.assign({}, this.form);
      delete innerData.auditRecords;
      delete innerData.processExecuteStatus;
      delete innerData.processId;
      delete innerData.processModelKey;
      delete innerData.processStatus;
      delete innerData.taskId;
      delete innerData.taskKey;
      delete innerData.taskRoles;
      delete innerData.taskUsers;
      delete innerData.status;
      delete innerData.creatorOrgId;
      innerData.reportTime =
        innerData.reportTime == null
          ? ""
          : this.$moment(innerData.reportTime, "YYYY-MM-DD").format(
              "YYYY年MM月DD日"
            );
      if (this.form.items != null && this.form.items.length > 0) {
        let i = 0;
        this.form.items.forEach((a) => {
          a.amount = a.amount == null ? "" : a.amount;
          a.remark = a.remark == null ? "" : a.remark;
          a.xiangmu = a.xiangmu == null ? "" : a.xiangmu;
          a.sort = ++i;
        });
        delete innerData.items;
        innerData.list = this.form.items;
      }

      // 处理申请人图片
      if (innerData.postUserPic != null && innerData.postUserPic != "") {
        innerData.postUserPic =
          "http://localhost:9000/" +
          innerData.postUserPic.substring(innerData.postUserPic.indexOf("uas"));
      }
      this.form.auditRecords.forEach((a) => {
        let key =
          "taskKey" + pinyin.getFullChars(a.taskKey.replace(/\d+/g, ""));
        if (a.taskPrintKey != null) {
          key = "taskKey" + a.taskPrintKey;
        }
        if (innerData[key] == null) {
          innerData[key] = [];
        }
        let auditPic = "";
        if (a.auditUserSignPicture != null && a.auditUserSignPicture != "") {
          let s = a.auditUserSignPicture.indexOf("uas");
          auditPic =
            "http://localhost:9000/" +
            a.auditUserSignPicture.substring(
              a.auditUserSignPicture.indexOf("uas")
            );
        }
        let task = {
          auditRemark: a.auditRemark,
          auditPic: auditPic,
          auditName: a.auditUserName,
          auditTime: this.$moment(a.time, "YYYY-MM-DD").format(
            "YYYY年MM月DD日"
          ),
        };
        innerData[key].push(task);
      });
      localStorage.setItem("innerData", JSON.stringify(innerData));
      localStorage.removeItem("publicFileId");
      const jump = this.$router.resolve({
        name: "wpsedit",
        query: {
          //要传的参数
          templateId: templateId,
          fileType: "public",
          openType: "create",
          fileName: "专项经费支出预算",
          tempUrl: "writeNumberTemp",
        },
      });

      window.open(jump.href, "_blank");
    },
  },
};
</script>
<style>
.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}
</style>
<style scoped>
.certain-category-search-wrapper
  >>> .certain-category-search.ant-select-auto-complete
  .ant-input-affix-wrapper
  .ant-input-suffix {
  right: 12px;
}
.certain-category-search-wrapper >>> .certain-search-item-count {
  position: absolute;
  color: #999;
  right: 16px;
}
.certain-category-search-wrapper
  >>> .certain-category-search.ant-select-focused
  .certain-category-icon {
  color: #108ee9;
}
.certain-category-search-wrapper >>> .certain-category-icon {
  color: #6e6e6e;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 16px;
}
</style>
