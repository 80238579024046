<template>
  <a-spin tip="加载中" :spinning="loading">
    <div class="tableList">
      <div class="search" v-if="info == null">
        <div class="search-body" style="height: 50px">
          <!--          <a-button type="danger" style="margin-left: 10px" @click="createnew"-->
          <!--            >新建</a-button-->
          <!--          >-->
          <a-button style="float: right" @click="gotoList" type="default"
            >返回</a-button
          >
        </div>
      </div>

      <div
        :style="{
          padding: '10px',
          width: info == null ? '90%' : '100%',
          margin: '0px auto',
        }"
      >
        <a-form-model
          ref="loanbillform"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <table style="width: 100%">
            <tr>
              <td
                colspan="3"
                style="
                  font-size: 40px;
                  text-align: center;
                  padding-bottom: 20px;
                "
              >
                借款单
              </td>
            </tr>
            <tr>
              <td>
                <a-form-model-item label="单位" prop="org">
                  <a-cascader
                    :options="orgs"
                    style="width: 280px"
                    v-model="form.orgId"
                    @change="orgChanged"
                    :fieldNames="{
                      label: 'name',
                      value: 'id',
                      children: 'children',
                    }"
                    placeholder="请选择部室"
                  />
                </a-form-model-item>
              </td>
              <td>
                <a-form-model-item label="时间" prop="time">
                  <a-date-picker v-model="form.time" valueFormat="YYYY-MM-DD" />
                </a-form-model-item>
              </td>
              <td>
                <a-form-model-item label="编号" prop="number">
                  <a-input
                    placeholder="请输入编号"
                    v-model="form.number"
                  ></a-input>
                </a-form-model-item>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="padding-top: 10px">
                <table
                  style="
                    border: 1px #cccccc solid;
                    width: 100%;
                    border-bottom: none;
                  "
                >
                  <tr>
                    <td
                      style="
                        width: 60%;
                        padding-top: 40px;
                        border-right: 1px #cccccc solid;
                        position: relative;
                        vertical-align: top;
                      "
                    >
                      <a-form-model-item
                        label="事由"
                        prop="description"
                        style="margin-left: 15px"
                        :label-col="{ span: 3 }"
                        :wrapper-col="{ span: 21 }"
                      >
                        <a-input
                          style="
                            border: none;
                            border-bottom: 1px #cccccc solid;
                            width: 80%;
                          "
                          v-model="form.description"
                        />
                      </a-form-model-item>
                      <a-form-model-item
                        label="金额"
                        prop="amount"
                        style="margin-left: 15px"
                        :label-col="{ span: 3 }"
                        :wrapper-col="{ span: 21 }"
                      >
                        <a-input
                          style="
                            border: none;
                            border-bottom: 1px #cccccc solid;
                            width: 80%;
                          "
                          v-model="form.amount"
                        />
                      </a-form-model-item>
                      <table
                        class="formtable"
                        style="border: none; position: absolute; bottom: 0px"
                      >
                        <tr style="height: 50px">
                          <td style="border-left: none">日期</td>
                          <td>报销金额</td>
                          <td>交回金额</td>
                          <td style="border-right: none">经办人</td>
                        </tr>
                        <template
                          v-for="(record, index) in form.loanBillDetailRecords"
                        >
                          <tr style="height: 50px" :key="index">
                            <td style="border-left: none">
                              <a-date-picker
                                v-model="record.time"
                                valueFormat="YYYY-MM-DD"
                              />
                            </td>
                            <td>
                              <a-input
                                style="
                                  border: none;
                                  border-bottom: 1px #cccccc solid;
                                  width: 80%;
                                "
                                v-model="record.reimbursementAmount"
                              />
                            </td>
                            <td>
                              <a-input
                                style="
                                  border: none;
                                  border-bottom: 1px #cccccc solid;
                                  width: 80%;
                                "
                                v-model="record.backAmount"
                              />
                            </td>
                            <td style="border-right: none">
                              <a-input-search
                                class="select_search"
                                readOnly
                                placeholder="请选择"
                                v-model="record.handlerName"
                                style="
                                  border: none;
                                  border-bottom: 1px #cccccc solid;
                                "
                                @click="onSearch(record, 2)"
                                @search="onSearch(record, 2)"
                              >
                                <a-button
                                  type="primary"
                                  slot="enterButton"
                                  icon="plus"
                                  title="添加"
                                />
                              </a-input-search>
                            </td>
                          </tr>
                        </template>
                      </table>
                    </td>
                    <td
                      style="
                        width: 40%;
                        padding: 20px;
                        vertical-align: top;
                        border-bottom: 1px #cccccc solid;
                      "
                    >
                      <table style="width: 100%">
                        <tr>
                          <td style="text-align: center">借款预算</td>
                        </tr>
                        <tr>
                          <td>
                            <a-form-model-item
                              label="1"
                              :label-col="{ span: 3 }"
                              :wrapper-col="{ span: 18 }"
                            >
                              <a-input
                                style="
                                  border: none;
                                  border-bottom: 1px #cccccc solid;
                                "
                                v-model="form.budget1"
                              />
                            </a-form-model-item>
                            <a-form-model-item
                              label="2"
                              :label-col="{ span: 3 }"
                              :wrapper-col="{ span: 18 }"
                            >
                              <a-input
                                style="
                                  border: none;
                                  border-bottom: 1px #cccccc solid;
                                "
                                v-model="form.budget2"
                              />
                            </a-form-model-item>
                            <a-form-model-item
                              label="3"
                              :label-col="{ span: 3 }"
                              :wrapper-col="{ span: 18 }"
                            >
                              <a-input
                                style="
                                  border: none;
                                  border-bottom: 1px #cccccc solid;
                                "
                                v-model="form.budget3"
                              />
                            </a-form-model-item>
                            <a-form-model-item
                              label="4"
                              :label-col="{ span: 3 }"
                              :wrapper-col="{ span: 18 }"
                            >
                              <a-input
                                style="
                                  border: none;
                                  border-bottom: 1px #cccccc solid;
                                "
                                v-model="form.budget4"
                              />
                            </a-form-model-item>
                            <a-form-model-item
                              label="借款人"
                              :label-col="{ span: 3 }"
                              :wrapper-col="{ span: 18 }"
                            >
                              <a-input-search
                                class="select_search"
                                readOnly
                                placeholder="请选择"
                                v-model="form.applyUserName"
                                style="
                                  border: none;
                                  border-bottom: 1px #cccccc solid;
                                "
                                @click="onSearch(form, 1)"
                                @search="onSearch(form, 1)"
                              >
                                <a-button
                                  type="primary"
                                  slot="enterButton"
                                  icon="plus"
                                  title="添加"
                                />
                              </a-input-search>
                            </a-form-model-item>
                            <a-form-model-item
                              label="借款形式"
                              :label-col="{ span: 3 }"
                              :wrapper-col="{ span: 18 }"
                            >
                              <a-radio-group
                                v-model="form.loadForm"
                                :options="plainOptions"
                              />
                            </a-form-model-item>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <a-row style="text-align: center; margin-top: 20px">
            <a-col>
              <a-button
                type="primary"
                v-if="form.status == 0 || form.status == 3"
                style="margin-right: 5px"
                @click="handleSubmit(0)"
                >保存</a-button
              >
              <a-popconfirm
                v-if="form.status == 0 || form.status == 3"
                title="提交后数据不可更改，是否确认？"
                @confirm="handleSubmit(1)"
              >
                <a-button type="danger">提交</a-button>
              </a-popconfirm>
              <a-button
                type="primary"
                v-if="form.processExecuteStatus && form.taskKey == '其他-审批'"
                style="margin-right: 5px"
                @click="showaudit()"
                >审批</a-button
              >
              <a-button
                type="default"
                v-if="form.status == 2"
                @click="showWps"
                class="cancelBtn"
                >打印</a-button
              >
            </a-col>
          </a-row>
        </a-form-model>
        <a-modal
          title="选择用户"
          v-model="selectuseModal.visible"
          width="70%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <selectuser @callback="selectuserCallback" />
        </a-modal>
        <a-modal
          title="审批"
          v-model="auditModal.visible"
          width="40%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <audit :info="auditModal.info" @callback="auditCallBack" />
        </a-modal>
      </div>
    </div>
  </a-spin>
</template>
<script>
import loanbillApi from "@/api/work/loanbill";
import orgApi from "@/api/org";
import selectuser from "../components/searchuser.vue";
import pinyin from "js-pinyin";

export default {
  name: "loanbill",
  data() {
    return {
      form: {
        status: 0,
        loanBillDetailRecords: [{}, {}, {}],
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      orgs: [],
      loading: false,
      selectuseModal: {
        visible: false,
        record: null,
        type: 0,
      },
      auditModal: {
        info: null,
        visible: false,
      },
      plainOptions: ["现金", "支票", "汇款", "其他"],
      rules: {
        org: [
          {
            required: true,
            message: "请选择部室",
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (this.form.orgId.length == 0) {
                callback(new Error(""));
                return;
              }
              callback();
            },
          },
        ],
        time: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        number: [
          {
            required: false,
            message: "请选择编号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    info: {
      type: Object,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    form: {
      handler(newValue, oldValue) {
        if (newValue.id == null || newValue.id == 0)
          localStorage.setItem("loanbill", JSON.stringify(newValue));
      },
      deep: true,
    },
  },
  components: { selectuser },
  mounted() {
    this.getOrgTree();
    if (this.info && this.info.id > 0) {
      this.getData();
    } else {
      // var item = JSON.parse(localStorage.getItem("loanbill"));
      var flag = false;
      // for (var key in item) {
      //   if (key != "status") {
      //     flag = true;
      //     break;
      //   }
      // }
      if (flag) {
        this.form = item;
        if (this.form.time) {
          this.form.time = this.$moment(this.form.time, "YYYY-MM-DD");
        }
        this.form.loanBillDetailRecords.forEach((item) => {
          if (item.time) {
            item.time = this.$moment(item.time, "YYYY-MM-DD");
          }
        });
      } else {
        var params = this.$route.params;
        if (params && params.status != null) {
          this.form = params;
        } else {
          // this.getLast();
        }
      }
    }
  },
  methods: {
    createnew() {
      this.form = { status: 0, loanBillDetailRecords: [{}, {}, {}] };
      this.getOrgTree();
      localStorage.removeItem("loanbill");
    },
    gotoList() {
      this.$router.push({ path: "/business/loanbilllist" });
    },
    showaudit() {
      this.auditModal.info = this.form;
      this.auditModal.visible = true;
    },
    auditCallBack(data) {
      this.auditModal.visible = false;
      this.$emit("callback", data != null);
    },
    orgChanged(v, selectedOptions) {
      if (selectedOptions == null || selectedOptions.length == 0) {
        this.form.orgName = null;
        this.form.orgId = [];
      } else {
        this.form.orgName = selectedOptions[selectedOptions.length - 1].name;
        this.form.orgId = v;
      }
      this.$forceUpdate();
    },
    onSearch(record, type) {
      this.selectuseModal.visible = true;
      this.selectuseModal.record = record;
      this.selectuseModal.type = type;
    },
    selectuserCallback(user) {
      if (this.selectuseModal.type == 1) {
        this.selectuseModal.record.applyUser = user.userName;
        this.selectuseModal.record.applyUserName = user.realName;
      } else {
        this.selectuseModal.record.handler = user.userName;
        this.selectuseModal.record.handlerName = user.realName;
      }

      this.selectuseModal.visible = false;
    },
    getOrgTree() {
      orgApi.getTree(true, true).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.orgs = res.data.tree;
          if (!this.info) {
            this.form.orgId = res.data.path;
            let tree = this.orgs;
            for (var i = 0; i < res.data.path.length; ++i) {
              var id = res.data.path[i];
              var find = tree.filter((n) => n.id == id);
              if (i == res.data.path.length - 1) {
                this.form.orgName = find[0].name;
              } else {
                tree = find[0].children;
              }
            }
            this.form.applyUserName = res.data.realName;
            this.form.applyUser = localStorage.getItem(
              this.$commons.User.userName
            );
          }
        }
      });
    },
    getData() {
      this.loading = true;
      loanbillApi.getData(this.info.id).then((res) => {
        this.loading = false;
        if (res.errorCode == this.$commons.RespCode.success) {
          res.data.orgId = res.data.orgPath;
          delete res.data.orgPath;
          // res.data.time = this.$moment(res.data.time, "YYYY-MM-DD");
          // if (res.data.loanBillDetailRecords) {
          //   for (var i = 0; i < res.data.loanBillDetailRecords.length; ++i) {
          //     var item = res.data.loanBillDetailRecords[i];
          //     if (item.time) {
          //       item.time = this.$moment(item.time, "YYYY-MM-DD");
          //     }
          //   }
          // }
          this.form = res.data;
          if (
            this.info &&
            this.info.taskId &&
            this.info.taskId != this.form.taskId
          ) {
            this.form.taskId = this.info.taskId;
          }
        }
      });
    },
    handleSubmit(status) {
      this.$refs.loanbillform.validate((valid) => {
        if (valid) {
          let formData = { ...this.form, status };
          formData.orgId = formData.orgId[formData.orgId.length - 1];
          this.loading = true;
          if (this.info) {
            formData.id = this.info.id;
          }
          delete formData.auditRecords;
          //添加
          loanbillApi
            .addOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == this.$commons.RespCode.success) {
                this.$message.success(res.errorMsg);
                if (this.modal) {
                  this.$emit("callback", true);
                } else {
                  this.gotoList();
                }
              } else {
                this.$message.error(res.errorMsg);
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    cancelClick() {
      this.$emit("callback", false);
    },
    showWps() {
      let templateId = "loanbill";

      // let innerData = Object.assign({}, this.form);
      let innerData = { ...this.form };
      delete innerData.auditRecords;
      delete innerData.processExecuteStatus;
      delete innerData.processId;
      delete innerData.processModelKey;
      delete innerData.processStatus;
      delete innerData.taskId;
      delete innerData.taskKey;
      delete innerData.taskRoles;
      delete innerData.taskUsers;
      delete innerData.status;
      delete innerData.creatorOrgId;
      innerData.time = this.$moment(innerData.time, "YYYY-MM-DD").format(
        "YYYY年MM月DD日"
      );
      innerData.orgName = "河北省教育科学研究院";
      innerData.amountStr = this.$util.menoyToUppercase(innerData.amount);
      // innerData.time = innerData.time.format("YYYY年MM月DD日")+'至 '+innerData.endTime.format("YYYY年MM月DD日")
      // let dataList = Object.assign([], innerData.loanBillDetailRecords);
      let dataList = [...innerData.loanBillDetailRecords];
      if (dataList != null && dataList.length > 0) {
        dataList.forEach((a) => {
          a.time = a.time
            ? this.$moment(a.time, "YYYY-MM-DD").format("YYYY年MM月DD日")
            : "";
        });
        delete innerData.loanBillDetailRecords;
        innerData.list = dataList;
      }
      this.form.auditRecords.forEach((a) => {
        let key =
          "taskKey" + pinyin.getFullChars(a.taskKey.replace(/\d+/g, ""));
        if (a.taskPrintKey != null) {
          key = "taskKey" + a.taskPrintKey;
        }
        if (innerData[key] == null) {
          innerData[key] = [];
        }
        let auditPic = "";
        if (a.auditUserSignPicture != null && a.auditUserSignPicture != "") {
          auditPic =
            "http://localhost:9000/" +
            a.auditUserSignPicture.substring(
              a.auditUserSignPicture.indexOf("uas")
            );
        }
        let task = {
          auditRemark: a.auditRemark,
          auditPic: auditPic,
          auditName: a.auditUserName,
          auditTime: this.$moment(a.time, "YYYY-MM-DD").format(
            "YYYY年MM月DD日"
          ),
        };
        innerData[key].push(task);
        // innerData['taskKeyFuShuJi ']=null;
      });
      localStorage.setItem("innerData", JSON.stringify(innerData));
      localStorage.removeItem("publicFileId");
      const jump = this.$router.resolve({
        name: "wpsedit",
        query: {
          //要传的参数
          templateId: templateId,
          fileType: "public",
          openType: "create",
          fileName: "借款单",
          tempUrl: "writeNumberTemp",
        },
      });

      window.open(jump.href, "_blank");
    },
  },
};
</script>
