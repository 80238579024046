<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div class="tableList">
      <div class="search" v-if="!modal">
        <div class="search-body" style="text-align: right">
          <a-button @click="gotoList">返回</a-button>
        </div>
      </div>
      <div style="text-align: center; font-size: 40px">出差审批单</div>
      <div class style="position: relative; text-align: center">
        <a-form-model
          ref="form"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          style="padding: 30px"
        >
          <a-row style="width: 80%; margin: 0 20% 0 10%">
            <a-col :span="12" style="text-align: left">
              <a-form-model-item label="单位" prop="company">
                <a-input
                  placeholder="请输入单位"
                  v-model="form.company"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" style="text-align: left">
              <a-form-model-item label="时间" prop="applyTime">
                <a-date-picker v-model="form.applyTime" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12" style="text-align: left">
              <a-form-model-item label="出差人" prop="outUserName">
                <a-input-search
                  class="select_search"
                  readOnly
                  placeholder="请选择"
                  v-model="form.outUserName"
                  @click="onSearch()"
                  @search="onSearch()"
                >
                  <a-button
                    type="primary"
                    slot="enterButton"
                    icon="plus"
                    title="添加"
                  />
                </a-input-search>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" style="text-align: left">
              <a-form-model-item label="职务" prop="duties">
                <a-select
                  v-model="form.duties"
                  placeholder="请选择"
                  @change="roleselected"
                >
                  <a-select-option
                    :value="tag.name"
                    v-for="tag in roleAll"
                    :key="tag.id"
                  >
                    {{ tag.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="text-align: left">
              <a-form-model-item
                :labelCol="{ span: 4 }"
                :wrapperCol="{ span: 16 }"
                label="随同出差人"
                prop="peerName"
              >
                <MultiSearchUser
                  :checkUsers="selUser"
                  @callback="selectUsersCallback"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="text-align: left">
              <a-form-model-item
                :labelCol="{ span: 4 }"
                :wrapperCol="{ span: 16 }"
                label="说明"
                prop="description"
              >
                <a-textarea
                  placeholder="请输入出差事由及出差目的说明"
                  v-model="form.description"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="5">
              <a-button
                type="primary"
                icon="plus"
                @click="addStroke"
                v-if="form.status == 0 || form.status == 3"
                style="margin-bottom: 8px"
                >添加</a-button
              >
            </a-col>
            <a-col :span="24">
              <a-table
                rowKey="id"
                size="small"
                bordered
                :columns="columns"
                :pagination="false"
                :dataSource="form.officialApprovalDetailList"
                :loading="isloading"
                :locale="{ emptyText: '暂无数据' }"
              >
                <template slot="outDate" slot-scope="text, record">
                  <a-date-picker v-model="record.outDate" style="width: 100%" />
                </template>
                <template slot="outNum" slot-scope="text, record">
                  <a-input-number v-model="record.outNum"></a-input-number>
                </template>
                <template slot="startPoint" slot-scope="text, record">
                  <a-cascader
                    :options="regionList"
                    v-model="record.startPoint"
                    placeholder="请选择出发地"
                    :fieldNames="{
                      label: 'name',
                      value: 'name',
                      children: 'children',
                    }"
                    change-on-select
                  />
                </template>
                <template slot="transport" slot-scope="text, record">
                  <a-select
                    v-model="record.transport"
                    placeholder="请选择交通工具"
                    style="width: 100px"
                  >
                    <a-select-option
                      :value="item.itemName"
                      v-for="item in transportList"
                      :key="item.id"
                      >{{ item.itemName }}</a-select-option
                    >
                  </a-select>
                </template>
                <template slot="lodgingStandard" slot-scope="text, record">
                  <a-input
                    v-model="record.lodgingStandard"
                    placeholder="请输入住宿标准"
                  ></a-input>
                </template>
                <template slot="receiveCompany" slot-scope="text, record">
                  <a-input
                    v-model="record.receiveCompany"
                    placeholder="请输入接待单位"
                  ></a-input>
                </template>
                <template slot="destination" slot-scope="text, record">
                  <a-cascader
                    :options="regionList"
                    v-model="record.destination"
                    @change="destinationChange($event, record)"
                    placeholder="请选择出目的地"
                    :fieldNames="{
                      label: 'name',
                      value: 'name',
                      children: 'children',
                    }"
                    change-on-select
                  />
                </template>
                <template slot="footer">
                  备注：<a-input
                    placeholder="请输入备注"
                    v-model="form.remark"
                    style="width: 90%"
                  ></a-input>
                </template>
                <span slot="action" slot-scope="text, record, index">
                  <a
                    v-if="form.status == 0 || form.status == 3"
                    @click="deleteStroke(index)"
                    >删除</a
                  >
                </span>
              </a-table>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <a-form-model-item label="填表人" prop="approvedBy">
                <div v-if="form.status > 0">
                  <div style="height: 40px"></div>
                  <div style="text-align: right">
                    <img
                      style="width: 120px"
                      :src="form.outUserPic"
                      v-if="form.outUserPic"
                    />
                    <span v-else>{{ form.outUserName }} </span>
                  </div>
                  <div style="text-align: right">
                    {{
                      $moment(form.createTime, "YYYY-MM-DD").format(
                        "YYYY年MM月DD日"
                      )
                    }}
                  </div>
                </div>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="财务" prop="leadership">
                <auditPrint
                  :infoData="form"
                  taskPrintKey="CaiWu"
                  @callback="auditCb"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="部室负责人" prop="completedBy">
                <auditPrint
                  :infoData="form"
                  taskPrintKey="BuShiFuZeRen"
                  @callback="auditCb"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <a-form-model-item label="分管院领导" prop="completedBy">
                <auditPrint
                  :infoData="form"
                  taskPrintKey="FenGuanLingDao"
                  @callback="auditCb"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="院长" prop="completedBy">
                <auditPrint
                  :infoData="form"
                  taskPrintKey="YuanChang"
                  @callback="auditCb"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col>
              <a-button
                type="primary"
                style="margin-right: 5px"
                v-if="form.status == 0 || form.status == 3"
                @click="handleSubmit(0)"
                >保存</a-button
              >
              <a-popconfirm
                v-if="form.status == 0 || form.status == 3"
                style="margin-right: 5px"
                title="提交后数据不可更改，是否确认？"
                @confirm="handleSubmit(1)"
              >
                <a-button type="danger">提交</a-button>
              </a-popconfirm>

              <a-button
                type="primary"
                style="margin-right: 5px"
                @click="showaudit()"
                v-if="form.processExecuteStatus && form.taskKey == '其他-审批'"
                >审批</a-button
              >
              <a-button
                type="default"
                v-if="form.status == 2"
                @click="showWps"
                style="margin-right: 5px"
                >打印</a-button
              >
              <a-button type="default" v-if="modal" @click="cancelClick"
                >取消</a-button
              >
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </div>
    <a-modal
      title="选择用户"
      v-model="selectuseModal.visible"
      width="70%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <selectuser @callback="selectUserCallback" />
    </a-modal>
    <a-modal
      title="审批"
      v-model="auditModal.visible"
      width="40%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <audit
        :info="auditModal.info"
        :formValidPass="formValidPass"
        @callback="auditCallBack"
      />
    </a-modal>
  </a-spin>
</template>

<script>
import orgApi from "@/api/org";
import roleApi from "@/api/role";
import selectuser from "../components/searchuser.vue";
import MultiSearchUser from "../components/MultiSearchUser.vue";
import officialApprovalApi from "@/api/work/officialApproval";
import regionApi from "@/api/system/region";
import commons from "@/utils/commons";
import pinyin from "js-pinyin";
import moment from "moment";
import dictionaryItemApi from "@/api/system/dictionaryItem";
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      roleAll: [],
      orgs: [],
      form: {
        company: "河北省教育科学研究院",
        status: 0,
        applyTime: moment(),
        officialApprovalDetailList: [],
      },
      selUser: [],
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      isloading: false,
      loadingTip: "加载中...",
      selectuseModal: {
        visible: false,
      },
      auditModal: {
        info: null,
        visible: false,
      },
      rules: {
        outUserName: [
          {
            required: true,
            message: "请选择出差人",
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (this.form.outUser == null || this.form.outUser == "") {
                callback(new Error(""));
                return;
              }
              callback();
            },
          },
        ],
        org: [
          {
            required: true,
            message: "请选择部室",
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (this.form.orgId.length == 0) {
                callback(new Error("请选择部室"));
                return;
              }
              callback();
            },
          },
        ],
        applyTime: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        days: [
          {
            required: true,
            message: "请输入出差天数",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "请输入出差事由",
            trigger: "blur",
          },
        ],
      },
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共 ${total} 条`,
      },
      columns: [
        {
          dataIndex: "ExpectedItinerary",
          align: "center",
          title: "预计行程",
          children: [
            {
              dataIndex: "outDate",
              align: "center",
              title: "时间",
              scopedSlots: { customRender: "outDate" },
            },
            {
              dataIndex: "outNum",
              align: "center",
              title: "人数",
              scopedSlots: { customRender: "outNum" },
            },
            {
              dataIndex: "startPoint",
              align: "center",
              title: "出发地",
              scopedSlots: { customRender: "startPoint" },
            },
            {
              dataIndex: "destination",
              align: "center",
              title: "目的地",
              scopedSlots: { customRender: "destination" },
            },
            {
              dataIndex: "transport",
              align: "center",
              title: "交通工具",
              scopedSlots: { customRender: "transport" },
            },
          ],
        },
        {
          dataIndex: "lodgingStandard",
          align: "center",
          title: "目的地住宿标准",
          scopedSlots: { customRender: "lodgingStandard" },
        },
        {
          dataIndex: "receiveCompany",
          align: "center",
          title: "接待单位",
          scopedSlots: { customRender: "receiveCompany" },
        },
        {
          dataIndex: "action",
          align: "center",
          width: 80,
          title: "操作",
          scopedSlots: { customRender: "action" },
        },
      ],
      regionList: [],
      transportList: [],
      lodgingStandardList: [],
      formValidPass: true,
    };
  },
  watch: {
    info: {
      handler(newValue, oldValue) {
        if (newValue.id == null || newValue.id == 0)
          localStorage.setItem(
            "officialApprovalInfo",
            JSON.stringify(newValue)
          );
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    regionApi.getList().then((res) => {
      this.regionList = this.$util.buildTree(res.data, 0, "id", "parId");
    });
    this.getDic();
    this.$nextTick(function () {
      if (this.info) {
        this.getData();
      } else {
        var item = JSON.parse(localStorage.getItem("officialApprovalInfo"));
        var flag = false;
        for (var key in item) {
          if (key != "status") {
            flag = true;
            break;
          }
        }
        if (flag) {
          if (!item.company) {
            item.company = "河北省教育科学研究院";
          }
          if (!item.officialApprovalDetailList) {
            item.officialApprovalDetailList = [];
          }
          this.form = { ...item };
        } else {
          var params = this.$route.params;
          if (params && params.status != null) {
            if (!params.officialApprovalDetailList) {
              params.officialApprovalDetailList = [];
            }
            if (!params.company) {
              params.company = "河北省教育科学研究院";
            }
            // params.applyTime = moment().format("YYYY-MM-DD");
            params.applyTime = moment();
            this.form = { ...params };
          } else {
            // this.getLast();
          }
        }
      }
      this.getOrgTree();
    });
  },
  components: {
    selectuser,
    MultiSearchUser,
  },
  methods: {
    getDic() {
      dictionaryItemApi.getItemByDictionaryName("交通工具").then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.transportList = res.data;
        }
      });
      dictionaryItemApi
        .getItemByDictionaryName("出差目的地住宿标准")
        .then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            this.lodgingStandardList = res.data;
          }
        });
    },
    destinationChange(e, record) {
      let city = e[e.length - 1];
      if (city == "市辖区") {
        city = e[e.length - 2];
      }
      let isPass = this.lodgingStandardList.find(
        (item) => item.itemName == city
      );
      if (isPass) {
        record.lodgingStandard = isPass.itemValue;
      } else {
        record.lodgingStandard = "";
      }
    },
    auditCb(data) {
      this.$emit("callback", data != null);
      this.getData();
    },
    addStroke() {
      let obj = {
        outDate: null,
        outNum: 0,
        startPoint: [],
        destination: [],
        transport: "",
        lodgingStandard: "",
        receiveCompany: "",
      };
      this.form.officialApprovalDetailList.push(obj);
    },
    deleteStroke(index) {
      this.$confirm({
        title: "您确定要删除此条数据吗?",
        okText: "确定",
        okType: "danger",
        cancelText: "我再想想",
        onOk: () => {
          this.form.officialApprovalDetailList.splice(index, 1);
        },
        onCancel() {},
      });
    },
    getOrgTree() {
      orgApi.getTree(true, true).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.orgs = res.data.tree;
          if (!this.info) {
            this.form.orgId = res.data.path;
            let tree = this.orgs;
            for (var i = 0; i < res.data.path.length; ++i) {
              var id = res.data.path[i];
              var find = tree.filter((n) => n.id == id);
              if (i == res.data.path.length - 1) {
                this.form.orgName = find[0].name;
              } else {
                tree = find[0].children;
              }
            }
            this.form.outUserName = res.data.realName;
            this.form.outUser = localStorage.getItem(
              this.$commons.User.userName
            );
            this.getUserRolesByName(this.form.outUser);
            this.$forceUpdate();
          }
        }
      });
    },
    roleselected(v) {
      // this.info.roleIds=v;
      this.$forceUpdate();
    },
    async showaudit() {
      let validRes = await this.$refs.form.validate();
      if (!validRes) {
        this.$message.error("请填写必填项");
        return;
      }
      this.auditModal.info = this.form;
      this.formValidPass = validRes;
      this.auditModal.visible = true;
    },
    async auditCallBack(data) {
      let close = true;
      this.auditModal.visible = false;
      if (data != null && data.updateModel) {
        close = false;
        let validRes = await this.$refs.form.validate();
        if (!validRes) {
          return false;
        }
        this.loading = true;
        this.loadingTip = "保存中...";
        let formData = {
          id: this.info.id,
          duties: this.form.duties,
          description: this.form.description,
          peerName: this.form.peerName,
          remark: this.form.remark,
          company: this.form.company,
          applyTime: this.form.applyTime,
          officialApprovalDetailList: [],
          updateOnly: true,
        };
        formData.applyTime = formData.applyTime.format("YYYY-MM-DD");
        this.form.officialApprovalDetailList.forEach((item) => {
          formData.officialApprovalDetailList.push({
            ...item,
            startPoint: item.startPoint.join("/"),
            destination: item.destination.join("/"),
          });
        });
        let res = await officialApprovalApi.addOrUpdate(formData);
        if (res.errorCode == "0000") {
          this.loading = false;
          close = true;
        }
      }
      if (close) {
        this.$emit("callback", data != null);
      }
    },
    cancelClick() {
      this.$emit("callback", false);
    },
    getLast() {
      officialApprovalApi.getLastForUser().then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          res.data.orgId = res.data.orgPath;
          res.data.applyTime = this.$moment(res.data.applyTime, "YYYY-MM-DD");
          delete res.data.orgPath;
          this.form = res.data;
        }
      });
    },
    createnew() {
      this.form = {
        status: 0,
        company: "河北省教育科学研究院",
        applyTime: moment(),
        officialApprovalDetailList: [],
      };
      this.getOrgTree();
      localStorage.removeItem("officialApprovalInfo");
    },
    gototravelexpense() {
      if (this.form.id == null || this.form.id == 0) {
        this.$message.error("当前信息没有提交，不能执行报销");
        return;
      }

      this.$router.push({ path: "/business/travelexpense" });
    },
    getData() {
      this.loading = true;
      officialApprovalApi.getData(this.info.id).then((res) => {
        this.loading = false;
        if (res.errorCode == this.$commons.RespCode.success) {
          res.data.orgId = res.data.orgPath;
          res.data.applyTime = this.$moment(res.data.applyTime, "YYYY-MM-DD");
          delete res.data.orgPath;
          if (res.data.userInfo) {
            this.selUser = JSON.parse(res.data.userInfo);
          }
          this.form = res.data;
          if (
            this.info &&
            this.info.taskId &&
            this.info.taskId != this.form.taskId
          ) {
            this.form.taskId = this.info.taskId;
          }
          if (
            res.data.officialApprovalDetailList &&
            res.data.officialApprovalDetailList.length > 0
          ) {
            res.data.officialApprovalDetailList.forEach((item) => {
              item.startPoint = item.startPoint.split("/");
              item.destination = item.destination.split("/");
            });
          }
        }
      });
    },
    gotoList() {
      this.$router.push({ path: "/business/officialoutsign" });
    },
    orgChanged(v) {
      this.form.orgId = v;
      this.$forceUpdate();
    },
    onSearch() {
      this.selectuseModal.visible = true;
    },
    selectUserCallback(user) {
      this.form.outUser = user.userName;
      this.form.outUserName = user.realName;
      this.selectuseModal.visible = false;
    },
    selectUsersCallback(users) {
      this.selUser = [...users];
      this.form.peerName = users.map((u) => u.realName).join(",");
    },
    getRoleAll() {
      roleApi
        .getRolesAll()
        .then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            this.roleAll = res.data;
          } else {
            this.$message.error(res.errorMsg);
          }

          this.isloading = false;
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    getUserRolesByName(userName) {
      roleApi.getUserRolesByName(userName).then((res) => {
        if (res.errorCode == "0000") {
          this.roleAll = res.data.filter((n) => n.name != "超级管理员");
          if (this.form.duties == "" || this.form.duties == null) {
            if (this.roleAll.length != null && this.roleAll.length > 0)
              this.form.duties = this.roleAll[0].name;
          }
        }
      });
    },
    handleSubmit(status) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let formData = { ...this.form, status };
          formData.applyTime = formData.applyTime.format("YYYY-MM-DD");
          formData.orgId = formData.orgId[formData.orgId.length - 1];
          formData.officialApprovalDetailList.forEach((item) => {
            item.startPoint = item.startPoint.join("/");
            item.destination = item.destination.join("/");
          });
          formData.userInfo = JSON.stringify(this.selUser);
          delete formData.auditRecords;
          this.loading = true;
          this.loadingTip = "保存中...";
          if (this.info) {
            formData.id = this.info.id;
          }
          let ss = localStorage.getItem(commons.User.userName);
          if (ss != null) {
            roleApi.getUserRolesByName(ss).then((res) => {
              if (res.errorCode == "0000") {
                let roles = new Array();
                for (let i = 0; i < res.data.length; i++) {
                  roles.push(res.data[i].name);
                }
                formData.user_roles = roles.toString();
                //添加/编辑
                officialApprovalApi
                  .addOrUpdate(formData)
                  .then((res) => {
                    if (res.errorCode == this.$commons.RespCode.success) {
                      this.$message.success(res.errorMsg);
                      localStorage.removeItem("officialApprovalInfo");
                      if (this.modal) {
                        this.$emit("callback", true);
                      } else {
                        this.gotoList();
                      }
                    } else {
                      this.$message.error(res.errorMsg);
                    }
                    this.loading = false;
                  })
                  .catch(() => {
                    this.loading = false;
                  });
              }
            });
          } else {
            this.$message.error("请重新登陆");
          }
        } else {
          return false;
        }
      });
    },
    showWps() {
      let templateId = "official_approval";

      let innerData = Object.assign({}, this.form);
      delete innerData.auditRecords;
      delete innerData.processExecuteStatus;
      delete innerData.processId;
      delete innerData.processModelKey;
      delete innerData.processStatus;
      delete innerData.taskId;
      delete innerData.taskKey;
      delete innerData.taskRoles;
      delete innerData.taskUsers;
      delete innerData.status;
      delete innerData.creatorOrgId;
      innerData.notInsertTableRow = "true";
      innerData.applyTime = this.$moment(
        innerData.applyTime,
        "YYYY-MM-DD"
      ).format("YYYY年MM月DD日");
      let list = [];
      innerData.officialApprovalDetailList.forEach((item) => {
        let outDate = item.outDate.split("-");
        list.push({
          ...item,
          startPoint: item.startPoint
            .filter(
              (item) =>
                item != "市辖区" &&
                item.indexOf("省") == -1 &&
                item.indexOf("自治区") == -1
            )
            .join("/"),
          destination: item.destination
            .filter(
              (item) =>
                item != "市辖区" &&
                item.indexOf("省") == -1 &&
                item.indexOf("自治区") == -1
            )
            .join("/"),
          outYear: outDate[0],
          outMonth: outDate[1],
          outDay: outDate[2],
        });
      });
      delete innerData.officialApprovalDetailList;
      innerData.list = [...list];

      if (innerData.outUserPic != null && innerData.outUserPic != "") {
        innerData.outUserPic =
          "http://localhost:9000/" +
          innerData.outUserPic.substring(innerData.outUserPic.indexOf("uas"));
      }

      this.form.auditRecords.forEach((a) => {
        let key =
          "taskKey" + pinyin.getFullChars(a.taskKey.replace(/\d+/g, ""));
        if (a.taskPrintKey != null) {
          key = "taskKey" + a.taskPrintKey;
        }
        if (innerData[key] == null) {
          innerData[key] = [];
        }
        let auditPic = "";
        if (a.auditUserSignPicture != null && a.auditUserSignPicture != "") {
          auditPic =
            "http://localhost:9000/" +
            a.auditUserSignPicture.substring(
              a.auditUserSignPicture.indexOf("uas")
            );
        }
        let task = {
          auditRemark: a.auditRemark,
          auditPic: auditPic,
          auditName: a.auditUserName,
          auditTime: this.$moment(a.time, "YYYY-MM-DD").format(
            "YYYY年MM月DD日"
          ),
        };
        innerData[key].push(task);
      });
      // if (innerData.duties.indexOf("院长") == 0) templateId = "officialoutYuan";
      localStorage.setItem("innerData", JSON.stringify(innerData));
      localStorage.removeItem("publicFileId");
      const jump = this.$router.resolve({
        name: "wpsedit",
        query: {
          //要传的参数
          templateId: templateId,
          fileType: "public",
          openType: "create",
          fileName: "出差审批单",
          tempUrl: "writeNumberTemp",
        },
      });

      window.open(jump.href, "_blank");
    },
  },
};
</script>
<style>
.edui-default .edui-toolbar {
  line-height: 20px !important;
}
</style>
